import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <Link to={post.frontmatter.path} className="post-link">
  <article className="card "  style={{backgroundImage: `url(${post.frontmatter.thumbnail})`}}>
  
    <header>
    
      <h2 className="post-title">
        <Link to={post.frontmatter.path} className="post-link">
          {post.frontmatter.title}
        </Link>
      </h2>
      <div className="post-meta">{post.frontmatter.date}</div>
    </header>
  </article>
  </Link>
)
export default PostLink
