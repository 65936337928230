import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <div className="hero-header">
        <h1 className="headline">{data.site.siteMetadata.home.title}</h1>
        <em className="intro">Product guy, founder, semi designer and wannabe techie</em>
        <p  className="primary-content">{data.site.siteMetadata.home.description}</p> 
        <p><a href="https://twitter.com/einargustafsson">@einargustafsson</a> | <a href="mailto:me@einargustafsson.com">me@einargustafsson.com</a> | <a href="https://www.linkedin.com/in/einargustafsson">LinkedIn</a></p>
        <Link to='/contact' className="button -primary">Reach out &rarr;</Link>
        
      </div>
    )}
  />
)