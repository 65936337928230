import React from "react"
import Helmet from 'react-helmet';
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <Helmet >
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <HeroHeader/>
        <div dangerouslySetInnerHTML={{ __html: `<style type="text/css">
        #gatsby-focus-wrapper {
          background: url('/assets/einar-advania-talk.jpg') 100% 0px;
          background-size: 70%;
          background-repeat: no-repeat;      
          }
          @media only screen and (max-width: 768px) {
            #gatsby-focus-wrapper {
              background-size: 100%;
            }
          }
          @media only screen and (max-width: 500px) {
            #gatsby-focus-wrapper {
              background-size: 140%;
            }
          }
        </style>` }} />
      
      <h2>Ramblings &darr;</h2>
      <p>Thoughts about product management, experience design, startups and coffee.</p>
      <div className="grids">
        {Posts}
      </div>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`
